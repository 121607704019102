import React, { useContext } from 'react'
import PageContext from '@context'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import renderBloks from '@renderBloks'
import { MarkdownHandler } from '@system'
import { Link as GatsbyLink } from 'gatsby'
import multilinkToUrl from '@helpers/multilink-to-url'

const useStyles = makeStyles((theme) => ({
  innerContainer: ({ image, isNav }) => ({
    textDecoration: 'none',
    position: 'relative',
    display: isNav ? 'flex' : 'block',
    justifyContent: 'start',
    flexDirection: 'column',
    padding: isNav ? '23px 0 0 0' : '0px',
    width: image.length ? '63%' : '100%',
  }),
  description: ({ isNav, variant }) => ({
    fontWeight: '400',
    color:
      variant === 'greyUnderlinedLink'
        ? theme.palette.primary.main
        : theme.palette.text.font,
    verticalAlign: 'text-top',
    marginBottom: isNav ? '7px' : variant === 'buildOn' ? '-25px' : '0px',
    marginTop: isNav ? '0px' : variant === 'buildOn' ? '-20px' : '0px',
    fontSize: isNav ? '11px' : theme.typography.body2.fontSize,
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.6,
      borderBottom: 'none',
    },
  }),
  productsCardContainer: ({ image, isNav, variant }) => ({
    padding: isNav
      ? '0'
      : image.length
      ? '0px 21px'
      : variant === 'bottomImage' || variant === 'buildOn'
      ? 0
      : '0px 7px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow:
      (variant === 'bottomImage' || variant === 'buildOn') &&
      '0px 4px 20px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  }),
  productsCardContainerLink: { textDecoration: 'none' },
  outerContainer: ({ isNav, variant }) => ({
    backgroundColor: variant === 'greyUnderlinedLink' ? '#f4f4f4' : null,
    border: `2px solid ${theme.palette.background.bottomNav}`,
    display: 'flex',
    width: 'auto',
    maxWidth: 'inherit',
    padding: isNav ? '0 0 0 20px' : '30px 25px 36px 25px',
    flex: '1',
    '&:hover': {
      backgroundColor:
        variant === 'hoverCards' &&
        `${theme.palette.background.bottomNav} !important`,
      '& h3': {
        color:
          variant === 'hoverCards' &&
          `${theme.palette.primary.main} !important`,
      },
      boxShadow: variant === 'hoverCards' && '0px 0px 15px rgba(0, 0, 0, 0.18)',
    },
  }),
  bottomContainer: ({ bottomSectionHeight, secondaryCta, variant }) => ({
    backgroundColor: variant === 'greyUnderlinedLink' ? '#f4f4f4' : null,
    display: 'flex',
    minHeight: bottomSectionHeight ? `${bottomSectionHeight}rem` : '62px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `2px solid ${theme.palette.background.bottomNav}`,
    borderTop: '0px',
    paddingLeft: variant === 'buildOn' && !!secondaryCta ? '23px' : 'inherit',
  }),
  imageContainerForProductModule: ({ isNav }) => ({
    width: isNav ? '32%' : '40%',
    bottom: isNav ? '-6px' : '0',
    alignSelf: isNav ? 'flex-end' : 'center',
    position: 'relative',
    right: isNav ? '-10px' : '-25px',
  }),
  imageContainerForProductModuleBottom: ({ isNav }) => ({
    width: '100%',
    bottom: isNav ? '10px' : '0px',
    left: '0',
    right: '0',
    position: 'relative',
  }),
  linkContainer: ({ variant }) => ({
    padding: '22px 30px',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '13px',
    [theme.breakpoints.only('md')]: {
      padding: '22px 5px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '22px 15px',
    },
    '& a': {
      width: 'fit-content',
      marginBottom: '5px',
      textDecoration: variant ? 'underline' : null,
    },
    '& :last-child': {
      marginBottom: '0px',
    },
  }),
  buttonContainer: {
    fontSize: '13px',
    padding: '11px 11px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.only('md')]: {
      padding: '11px 8px',
    },
  },
}))

const UnifiedProductCard = (props) => {
  const isInEditor = useContext(PageContext).isInEditor
  const {
    description,
    name,
    button,
    image,
    secondaryCta,
    linksTo,
    bottomImage,
  } = props.blok
  const { isNav } = props

  const classes = useStyles({
    ...props.blok,
    isNav,
    secondaryCta,
  })

  // variant choices: "bottomImage" || "hoverCards" || "buildOn"

  const href = multilinkToUrl(linksTo, isInEditor)
  const hasCta = !!button.length || !!secondaryCta.length

  return (
    <SbEditable content={props.blok}>
      {!href ? (
        <Box className={classes.productsCardContainer}>
          <Box className={classes.outerContainer}>
            <Box className={classes.innerContainer}>
              {renderBloks(name, {
                isProductCard: true,
              })}
              {!!description && (
                <MarkdownHandler className={classes.description}>
                  {description}
                </MarkdownHandler>
              )}
            </Box>
            {!!image.length && (
              <Box className={classes.imageContainerForProductModule}>
                {renderBloks(image)}
              </Box>
            )}
          </Box>
          {hasCta && (
            <Box className={classes.bottomContainer}>
              {!!secondaryCta.length && (
                <Box className={classes.linkContainer}>
                  {renderBloks(secondaryCta)}
                </Box>
              )}
              {!!button.length && (
                <Box className={classes.buttonContainer}>
                  {renderBloks(button)}
                </Box>
              )}
            </Box>
          )}
          {!!bottomImage && !!bottomImage.length && (
            <Box className={classes.imageContainerForProductModuleBottom}>
              {renderBloks(bottomImage)}
            </Box>
          )}
        </Box>
      ) : (
        <Box
          className={classes.productsCardContainerLink}
          component={GatsbyLink}
          href={href}
        >
          <Box className={classes.productsCardContainer}>
            <Box className={classes.outerContainer}>
              <Box className={classes.innerContainer}>
                {renderBloks(name, {
                  isProductCard: true,
                  isNav: props.isNav,
                })}
                {!!description && (
                  <MarkdownHandler className={classes.description}>
                    {description}
                  </MarkdownHandler>
                )}
              </Box>
              {!!image.length && (
                <Box className={classes.imageContainerForProductModule}>
                  {renderBloks(image)}
                </Box>
              )}
            </Box>
            {hasCta && (
              <Box className={classes.bottomContainer}>
                {!!secondaryCta.length && (
                  <Box className={classes.linkContainer}>
                    {renderBloks(secondaryCta)}
                  </Box>
                )}
                {!!button.length && (
                  <Box className={classes.buttonContainer}>
                    {renderBloks(button)}
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {!!bottomImage && !!bottomImage.length && (
            <Box className={classes.imageContainerForProductModuleBottom}>
              {renderBloks(bottomImage)}
            </Box>
          )}
        </Box>
      )}
    </SbEditable>
  )
}

export default UnifiedProductCard
